/* eslint-disable no-unused-expressions */
import React from "react"
import { graphql } from "gatsby"

import Section from "../components/wwc/layout/Section/Section"
import Row from "../components/wwc/layout/Row/Row"
import Column from "../components/wwc/layout/Column/Column"
import Title from "../components/wwc/text/Title/Title"

const NotFoundPage = ({ data, ...props }) => {
  return (
    <>
      <Section>
        <Row>
          <Column>
            <Title h1 className={`section__title error404`}>
              NOT FOUND
            </Title>
            <Title h4 className={`section__subtitle`}>
              You just hit a route that doesn&#39;t exist... the sadness.
            </Title>
          </Column>
        </Row>
      </Section>
    </>
  )
}

export const query = graphql`
  query NotFound($locale: String) {
    siteJson(locale: { eq: $locale }) {
      ...SiteData
    }
  }
`

export default NotFoundPage
